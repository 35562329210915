/*
 * GENERAL CLASSES
 *
 * In many cases you'll need the same css multiple times for different elements throughout your website.
 * Place the classes with those css in this file.
 *
 * -----------------------------
 * Table of Contents:
 *
 * 1.   Menu
 * 2.   Header
 * 3.   Buttons
 * 4.   Images
 * 5.   Sections
 * 6.   Content
 * 7.   Filter
 * 8.   Columns
 * 9.   Grid Items
 * 10.  Social Media
 * 11.  Slider
 * 12.  Album
 * 13.  Footer
 * 14.  Vacancies
 * 15.  Flexbox
 */

/* Default browser style override */
select {appearance: none !important;}
*::selection{ color:$white; background-color:$blue; }
button:focus{outline:none;}

.table {display: table;}

.vertical-align {
    display: table-cell;
    vertical-align: middle;
}
.small-text-center{text-align: center;}

.relative{position: relative;}
.overflow{overflow: hidden;}
.bold{font-weight: bold;}
.img-container {
    overflow: hidden;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.font-size-small{font-size:1.3rem;}

.button {
    &.round {border-radius: 50%;}
    &.round-small {padding: 0.5em 0.59em;
        &.green{
            background-color: $green;
            color: $white;
        }
    }
}
.button-anchor{
    color:$anchor-color;
    &:hover{color:$anchor-color-hover;}
}

.g-recaptcha {
    text-align:center;
    > div{margin-left:auto; margin-right:auto;}
    iframe{transform:scale(0.75);}
}

.iframe-responsive{height: rem-calc(340);}

.background-primary-color{background-color:$primary-color !important;}
.contrast-color{color:$white;}

.heading-size-medium{font-size:2.2rem;}

// 1. Menu
.main-menu {
    width: 100%; padding: 2rem;
    z-index: 999;
}

.hamburger-icon {
    width: rem-calc(25); height: rem-calc(25); margin: 0;
    position: absolute; top: 58%; right: 0;
    list-style-type: none;
    transform: translateY(-42%);
    .menu-line {
        width: 100%; height: 2px;
        position: absolute;
        background: $blue;
        opacity: 1;
        transform: translateY(0px); transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
        &:nth-child(2) {transform: translateY(8.75px);}
        &:last-child {transform: translateY(17.75px);}    
        &.green{
            background-color: $green;
        }
    }
}
.mobile-menu {
    &.is-active {transform: translateY(0%);}
    width: 100%; height: 100%; padding: rem-calc(20);
    position: fixed; top: 0; left: 0; bottom: 0; right: 0; z-index: 999;
    background: transparentize($blue, .1);
    transform: translateY(-130%);
    transition: transform .5s ease-in-out;
    &.green{
        background: transparentize($green, .1);
    }
}
.mobile-menu-btn {
    width:105px; height:40px;
    position:fixed; z-index:1000; top:rem-calc(28); right:rem-calc(30);
    cursor:pointer;
    &.is-active {
        .mobile-menu-text {color: $white;}
        .menu-line {
            background: $white;
            &:first-child {transform: translate(-4px,9px) rotate(50deg);}
            &:nth-child(2) {opacity: 0;}
            &:last-child {transform: translate(-4px,9px) rotate(-50deg);}        
        }
    }
}

.mobile-menu-text {
    position: absolute; right: rem-calc(40); top: 50%;
    transform: translateY(-50%);
}

.menu-anchor {
    margin: 0rem 2rem;
    font-size: rem-calc(20); font-weight: 500; text-transform: uppercase; color: $white; line-height: rem-calc(30) !important;
    &:hover {color: $white;}
}
.menu {
    width: 100%;
    position: absolute; left: 0; top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    li {width: 100%;}
}

.menu-fixed {
    width: 100%;
    position: fixed; z-index: 999; top: 0;
    background: transparentize($white, .1);
}

.menu-logo-wrapper {
    width: 10rem; padding:rem-calc(10);
    position: absolute; top: -(rem-calc(20)); left:0;
    overflow: hidden;
}

.menu-logo {
    width:100%; 
    position: relative; z-index: 9999;
    text-align: center;
    transform: translateY(-200%);
    transition: transform .3s ease;
    .svg-logo{width:100%; height:auto;}
}
.svg-logo {width: rem-calc(70); height: rem-calc(70);}

.is-sticky-logo {transform: translateY(0%);}

// 2. Header
.header-wrapper {position: relative;}

.header-top-bar {padding: rem-calc(15);}

.header-item {height: rem-calc(100);}

.green-heading{
    color: $green;
}

// 3. Buttons & links
.login-btn {
    width:rem-calc(150); padding: rem-calc(5.5) 0;
    background-color: transparent; border: 1px solid $medium-gray; border-radius: rem-calc(15);
    text-transform: uppercase; color: $medium-gray; text-align: center;
    &.green-login-hover{
        &:hover, &:focus{
            background-color: $green;
        }
    }
}

.filter-buttons-group{margin: 0;}
.filter-btn {
    margin: rem-calc(5); padding: rem-calc(15);
    background-color: $gray; border-radius: 0;
    color: $blue; font-size: rem-calc(14);
    &.is-active, &:hover {
        background-color: $blue;
        color: $white;
        &:focus {
            background-color: $blue;
            color: $white;
        }
    }
    &:focus {
        background-color: $gray;
        color: $blue;
    }
}

// Green button
.button{
    &.green{
        background-color: $green; 
        color: $white; 
        &:hover{
            background-color: darken($green, 20%);
        }
    }
}

.filter-btn[data-town=all] {padding: rem-calc(15) rem-calc(63);}

.call-to-action {
    margin-top: rem-calc(40);
    font-weight: 500; text-transform: uppercase;
    &.green-hover{
        color: $green;
        &:hover{
            color: darken($green, 20%);   
        }
    }
}

.white-btn {
    padding: 1.2rem 5rem;
    background: $white;
    color: $blue;
    &.green-hover{
        color: $green;
        &:hover,&:focus{
            color: $white;
            background-color: darken($green, 20%);
        }
    }
}

.blue-btn {
    padding: 1.2rem 5rem;
    background: $blue;
    color: $white;
}

.green-btn {
    padding: 1.2rem 5rem;
    color: $white;
    background: $green;
    &:hover, &:focus{
        color: $white;
        background-color: darken($green, 20%);
    }
    &.button[disabled]{
        background-color: darken($green, 20%);
        &:hover{
            color: $white;
            background-color: darken($green, 20%);
        }
    }
}

.green-hollow-btn{
    padding: 1.2rem 5rem;
    background-color: $white;
    color: $green;
    border: 1px solid $green;
    &:hover, &:focus{
        background-color: darken($green, 20%);
        border-color: darken($green, 20%);
        color: $white;
    }
}

.icn {
    padding-right: rem-calc(10);
    position: relative; top: rem-calc(2);
}
.icn-label{
    margin-left:.5rem;
    font-size:1.6rem;
}

.link.green{
    color: $green;
    &:hover{
        color: darken($green, 20%); 
    }
}

// 4. Images
.image-wrapper{margin:0 0 30px 0;}
.fullsize-single-image {
    position: relative;
    img{width: 100%;}
}
.single-image-text-centered {
    width: 100%;
    position: absolute; top: 50%;
    font-weight: 500; text-align: center; color: $white; font-size: rem-calc(25);
    transform: translateY(-50%);
}

// 5. Sections
.section {
    padding: rem-calc(40) rem-calc(10);
    position: relative;
}

.section-image-overlay {
    background-repeat: no-repeat; background-position: center center; background-size: cover;
    &:before {
        width: 100%; height: 100%;
        position: absolute; top: 0; left: 0; right: 0; bottom: 0;
        background: transparentize($light-blue, .2);
        content: " ";
    }
}

.above-overlay {position: relative; z-index: 1;}

.section-form {
    padding: rem-calc(30) 0; margin-top: rem-calc(30); margin-bottom: rem-calc(30);
    position: relative;
}

.section-title {
    margin-bottom:2rem;
    font-weight: 500; color: $blue; text-align: center;
    &.small-padding{padding-bottom:2rem;}
    &.green{
        color: $green;
    }
}

// 6. Content
.content-header {margin: rem-calc(30) 0;}

// 7. Filter
.category-items-wrapper {
    margin: 0;
    display: inline-block;
}

// 8 Columns
.column-gutter {
    padding: rem-calc(10);
    word-wrap: break-word;
}

.column-gutter-medium {
    padding: rem-calc(20);
    word-wrap: break-word;
}

.divider-container {
    .columns {
        &:first-child {margin-bottom:30px;}
    }
    &.divider-margin-top{
        margin-top: 3rem;
    }
}
.green-header{
    color: $green;
    font-weight: bold;
}

// 9. Grid items
.grid-block {
    margin: rem-calc(map-get($grid-column-gutter, small)/2) auto;
    position: relative;
}

.vertical-centered-content {
    width: 100%; padding:rem-calc(20) rem-calc(10);
    position: absolute; top: 50%; left: 0; z-index: 1;
    color: $white; text-align: center;
    transform: translateY(-50%);
    word-wrap: break-word;
}

.grid-title {
    font-size:1.6rem; font-weight: bold; text-transform: uppercase; color: $white;
    transition: top .6s ease;
}

.grid-description {
    opacity: 0;
    bottom: 0;
    transition: bottom .6s ease, opacity .6s ease;
}

.grid-padding {padding: rem-calc(20);}

.grid-content-hoverable {
    width: 100%; height: 100%;
    position: absolute; top: 0; left: 0; bottom: 0; right: 0;
    &:after {
        width: 100%; height: 100%;
        position: absolute; top: 0; left: 0; bottom: 0; right: 0;
        background-color: rgba($black, .25);
        content: " ";
        transition: background .6s ease;
    }
    &.is-active, &:hover, &.is-not-active {
        .grid-title, .grid-description{opacity: 1;}
        .grid-title{top: 25%;}
        .grid-description{bottom: 10%;}    
    }
    &.is-not-active {
        cursor:default;
        &:after{background: transparentize($light-blue, .2);}
        .grid-title, .grid-description{color: $dark-gray;}
        .is-filled{ display: none;}
        .is-not-filled{ opacity: 1;}
    }
    .is-filled {display: block;}
    .is-not-filled {opacity: 0;}
}

.grid-footer {
    margin: 0 rem-calc(20); padding: rem-calc(20) 0;
    border-top: 1px solid $light-blue;
}

// 10. Social Media
.social-media-spacing {
    a {
        margin-left: rem-calc(15);
        &:first-child {margin-left: 0;}
    }
}

.social-media-title {
    width: 100%; margin-bottom: rem-calc(10);
    display: inline-block;
}

// 11. Slider
.js-lightcase{cursor: pointer;}
.slider[data-slider-type=blocks]{margin-bottom: rem-calc(20);}
.slider-arrows-container{margin-bottom:rem-calc(20); text-align: center;}
.slider-arrow{
    margin: auto 0.8rem;
    font-size: rem-calc(13);
}

.slide-block{
    position: relative;
    height: auto;
    margin: 0 rem-calc(35);
    &:after{
        width: 100%; height: 100%;
        position: absolute; top: 0; left: 0; bottom: 0; right: 0;
        background: transparent;
        content: " ";
        transition: background .6s ease;
    }
    &:hover{
        &:after{background: transparentize($blue, .4);}
        .slide-content{opacity: 1;}
    }
}

.slide-no-hover{
    &:hover{
        &:after{background: transparent;}
    }
}

.slide-content {
    opacity: 0;
    transition: opacity .6s ease;
}

.slide-title {font-weight: bold;}

.slide-description {
    font-size: rem-calc(14);
    margin-bottom: 0;
}

.slick-arrow {
    width: rem-calc(45); height: rem-calc(90);
    position: absolute; top: 50%;
    background: transparentize($light-blue, .5);
    color: $white;
    transform: translateY(-50%);
    transition: background .4s ease;
    outline: none;
    &:hover {background: transparentize($light-blue, .8);}
}

.slick-prev {
    left: 0;
    border-radius: 0 rem-calc(90) rem-calc(90) 0;
    .slick-arrow-icon {
        position: relative;
        left: -(rem-calc(2));
    }
}

.slick-next {
    right: 0;
    border-radius: rem-calc(90) 0 0 rem-calc(90);
    .slick-arrow-icon {
        position: relative;
        right: -(rem-calc(4));
    }
}
.slick-track{margin:0 auto;}

// 12. Album
.album-container {margin: rem-calc(40) auto 0 auto; padding: rem-calc(25) 0 rem-calc(60) 0;}

.album-text {
    margin: rem-calc(20) rem-calc(35);
    color: $dark-gray;
}

// 13. Footer
.footer{padding-bottom: 0;}
.footer-column{margin: rem-calc(map-get($grid-column-gutter, small)/2) 0; padding: 0 rem-calc(30);
    &.black{
        color: $black;
    }
    .green{
        color: $green;
    }
}
.footer-line-wrapper {
    margin-top: rem-calc(50);
    text-align: center;
    li {display: inline-block;}
}
.footer-line {
    width: 45%;
    border-bottom: 1px solid $blue;
    &.green{
        border-bottom: 1px solid $green;
    }
}
.footer-small-logo{width: 6%;}
.footer-social-media{margin-top: rem-calc(15);
    .social-txt{
        &.green{
            color: $green;
        }
    }
}

// 14. Vacancies
.vacancy-category-btn{
    width: 100%; margin-top: rem-calc(20); padding: rem-calc(12) rem-calc(20);
    font-size: rem-calc(12);
}

// 15. Flexbox
.flex-container {
    display: flex;
}

.flex-col {
    flex: 0 1 auto;
}

.flex-col-shrink {
    flex-shrink: 0;
}

.flex-align-middle {
    align-items: center;
}

.flex-gap-1 {
    gap: rem-calc(8);
}

.flex-gap-2 {
    gap: rem-calc(16);
}

@include breakpoint(medium) { /* min-width: 520 */

    .vertical-centered-content {padding:rem-calc(20);}
    .grid-title {font-size:1.8rem;}
    .grid-block {margin: rem-calc(map-get($grid-column-gutter, medium)/2) auto;}
    .grid-gutter-large {margin: rem-calc(map-get($grid-column-gutter, medium)+5) rem-calc(map-get($grid-column-gutter, medium)/2);}
    .grid-footer {padding: rem-calc(20);}

    .single-image-text-centered {font-size: rem-calc(30);}
    .iframe-responsive {height: rem-calc(450);}
    .medium-wrapper-smaller {padding: 0 rem-calc(20); margin: 0 !important;}

    .section-title {margin-bottom:4rem;}

    // Slider
    .about-slider{margin-right:rem-calc(10);}

}

@include breakpoint(large) { /* min-width: 960 */
    .heading-size-medium{font-size:2.6rem;}
    
    .grid-block{margin: rem-calc(map-get($grid-column-gutter, large)/2) 0;}
    .grid-gutter-large{margin: rem-calc(map-get($grid-column-gutter, large)+5) rem-calc(map-get($grid-column-gutter, large)/2);}
    .single-image-text-centered{font-size: rem-calc(40);}
    .social-media-title{width: auto;}

    // Slider
    .about-slider{margin-right:rem-calc(35);}

    .main-menu {
        height: auto;
        position: absolute; top: inherit; left: inherit; bottom: inherit; right: inherit;
        background: transparentize($gray, .2);
        transform: translateY(0%);
        li {width: auto;}
    }

    .menu {
        width: auto;
        position: relative; top: inherit;
        transform: translateY(0%);
    }

    .menu-anchor {
        font-size: inherit; color: $dark-gray;
        line-height: inherit !important; // Requires !important to overwrite _menu.scss class
        &:hover {color: $blue;}
    }
    .small-text-center{text-align: inherit;}
    .iframe-responsive{height: rem-calc(600);}
    .section{padding: 6rem 2rem;}
    .category-items-wrapper{margin: rem-calc(50) 0;}

    .vacancy-category-btn {
        width: auto; margin-top: rem-calc(40); padding: rem-calc(12) rem-calc(50);
        font-size: rem-calc(16);
    }

    .filter-buttons-group {margin: 0 rem-calc(70) 0 rem-calc(70);}
    .filter-btn {font-size: rem-calc(16);}

    .svg-logo {width: rem-calc(100); height: rem-calc(100);}

    .large-captcha-absolute {
        width: 100%; margin: 0 auto;
        position: absolute; top: 70%; bottom: 0; left: 0;
        text-align:center;
        iframe{transform:scale(.7);}
        .g-recaptcha > div{margin:auto;}
    }

    .divider-container {
        .columns {
            &:first-child {margin-bottom:0; padding-right: rem-calc(30);}
            &:last-child {padding-left: rem-calc(30);}
        }
    }

    .divider{
        height: 100%; width: 1px;
        position: absolute; left: 50%; top: 0; bottom: 0;
        background: $medium-gray;
    }
}

@include breakpoint(xlarge) { // 1200
    .grid-content-hoverable {
        &.is-active, &:hover, &.is-not-active {
            &:after{background: transparentize($blue, .5);}
        }
        &.is-not-active {
            &:after{background: transparentize($light-blue, .2);}
        }
    }   
}